import React from 'react';
import loadjs from 'loadjs';
import axios from 'axios';

class Animation extends React.Component {
  componentDidMount() {
    axios.get('/assets/animation/data.json').then(({data}) => {
      const options = {
        prerender: true,
        renderer: 'svg',
        wrapper: this.wrapper,
        loop: true,
        autoplay: true,
        animationData: data,
      };

      const initialize = function() {
        this.animation = window.bodymovin.loadAnimation(options);
      };

      loadjs('/assets/js/bodymovin_light.min.js', {
        success: initialize.bind(this),
      });
    });
  }

  componentWillUnmount() {
    if (this.animation) {
      this.animation.destroy()
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const storeWrapper = (el) => {
      this.wrapper = el;
    };

    return <div className='react-bodymovin-container' ref={storeWrapper} />;
  }
}

export default Animation;
